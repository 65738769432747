(function ($) {
  var lang =document.documentElement.lang.substr(0, 2);
  if (lang == "da") {
    $( ".menu" ).prepend( "<li class='menu-item'><a href='https://danbred.com/' target='_blank'>DANBRED.COM</a></li>" );
  } else {
  $( ".menu" ).prepend( "<li class='menu-item'><a href='https://danbred.com/" + lang + "' target='_blank'>DANBRED.COM</a></li>" );
}
  // Anchor menu smoothing

  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      if (
        location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ?
          target :
          $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          event.preventDefault();
          $("html, body").animate({
              scrollTop: target.offset().top
            },
            1000,
            function () {
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                $("section.focus").removeClass("focus");
                $target.addClass("focus");
                return false;
              } else {
                $target.attr("tabindex", "-1");
                $("section.focus").removeClass("focus");
                $target.addClass("focus");
                $target.focus();
              }
            }
          );
        }
      }
    });

  // Add class on TOC click

  $(function () {
    $(".toc li").on("click", function () {
      $("li.active").removeClass("active");
      $(this).addClass("active");
    });
  });

    // Sticky Menu on scroll
    $(window).scroll(function () {
      var winHeight = $(this).height();
      var scrollTop = $(this).scrollTop();

      $("#hero").each(function (index) {
        var elemHeight = $(this).height() - 100;
        var elementTop = $(this).position().top;

        if (
          elementTop < scrollTop + winHeight &&
          scrollTop < elementTop + elemHeight
        )
          $(".toc")
          .eq(index)
          .removeClass("fixed-toc");
        else
          $(".toc")
          .eq(index)
          .addClass("fixed-toc");


          // Hide when user reaches bottom
if ($('body').height() - 600 <= ($(window).height() + $(window).scrollTop())) {
            $(".toc").fadeOut()
            .eq(index)
            .removeClass("fixed-toc").fadeOut();
        }else
        $(".toc").fadeIn();
      
        if (
          elementTop < scrollTop + winHeight &&
          scrollTop < elementTop + elemHeight
        )
          $(".blockedOut")
          .eq(index)
          .removeClass("fixed-block");
        else
          $(".blockedOut")
          .eq(index)
          .addClass("fixed-block");

        if (
          elementTop < scrollTop + winHeight &&
          scrollTop < elementTop + elemHeight
        )
          $(".blockedText")
          .eq(index)
          .removeClass("fixed-block-text");
        else
          $(".blockedText")
          .eq(index)
          .addClass("fixed-block-text");
      });
      // funcion to highlight menuitem on scroll
      $(".sideMenuAnchor").each(function (index) {
        if ($(this).isInViewport()) {
          var currentElm = $(this)
            .find("h3")
            .text();
          $(".toc")
            .find(".nav-section1")
            .each(function () {
              var currentMenuItem = $(this).text();
              if (currentMenuItem.indexOf(currentElm) != -1) {
                $(this)
                  .parent()
                  .addClass("active");
                $(this)
                  .parent()
                  .siblings()
                  .removeClass("active");
              }
            });
        }
      });
    });
    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return (
        elementBottom + 300 > viewportTop && elementTop + 500 < viewportBottom
      );
    };

  // Mobile Menu
  $(".navToggle").click(function () {
    $(".mobile-menu").toggleClass("menuOn");
    $("nav").toggleClass("navOn");
  });

  // skjuler sidebar menu på søgeresultatssiden
  $(function () {
    if (
      window.location.href.indexOf("/?s=") >= 0 ||
      $(".toc").find("ul").length == 0
    ) {
      $(".toc").hide();
    } else {
      $(".toc").show();
    }
  });

  // funktion til at åbne billeder i modal
  $(function () {
    var imgsToOpenInModal = $("#logM").find("img");
    if (imgsToOpenInModal) {
      for (var i = 0; i < imgsToOpenInModal.length; i++) {
        if (
          $(imgsToOpenInModal[i]).parent(".front-icon").length != 1 &&
          $(imgsToOpenInModal[i]).siblings(".front-icon").length == 0
        ) {
          if ($(imgsToOpenInModal[i]).hasClass("noClick")) {
            return false;
          } else {
            $(imgsToOpenInModal[i]).addClass("clickToZoom");
            $(imgsToOpenInModal[i]).attr("data-toggle", "modal");
            $(imgsToOpenInModal[i]).attr("data-target", "#imgModal");
          }
        }
      }
    }
    $(".clickToZoom").click(function () {
      var imgSrc = $(this).attr("src");
      var imgHeight = $(this).height();
      var imgWidth = $(this).width();
      // $("#imgDisplaying").css({"background-image": "url('"+imgSrc+"')", "height": imgHeight, "width": imgWidth-5});
      $("#imgToShowBig").attr("src", imgSrc);
      $(".modal-content").css({
        width: "100%"
      });
      $(".modal-dialog").css({
        width: "100%"
      });
    });
  });

  $(function () {
    $("#logM input[type='submit']")
      .addClass("btn-primary btn")
      .removeClass("search-submit")
      .css({
        "margin-left": "15px"
      });
  });

})(jQuery);


// Remove login on Specific pages
jQuery(document).ready(function($) {
  /* Float Label Pattern Plugin for Bootstrap 3.1.0 by Travis Wilson */
  $(function () {
    $.fn.floatLabels = function (options) {
      // Settings
      var self = this;
      var settings = $.extend({}, options);
      // Actions
      var actions = {
        initialize: function () {
          self.each(function () {
            var $this = $(this);
            var $label = $this.children("label");
            var $field = $this.find("input,textarea").first();
            if ($this.children().first().is("label")) {
              $this.children().first().remove();
              $this.append($label);
            }
            var placeholderText =
              $field.attr("placeholder") &&
              $field.attr("placeholder") !== $label.text()
                ? $field.attr("placeholder")
                : $label.text();
            $label.data("placeholder-text", placeholderText);
            $label.data("original-text", $label.text());
            if ($field.val() === "") {
              $field.addClass("empty");
            }
          });
        },
        swapLabels: function (field) {
          var $field = $(field);
          var $label = $(field).siblings("label").first();
          var isEmpty = Boolean($field.val());
          if (isEmpty) {
            $field.removeClass("empty");
            $label.text($label.data("original-text"));
          } else {
            $field.addClass("empty");
            $label.text($label.data("placeholder-text"));
          }
        },
      };
      // Portal Items
      $(".gof_free").on("click", function () {
        $("#db-portal-fade").hide();
        $("#db-portal-fade").fadeIn(1000);
        $(".gof-free").show();
        $(".gof-gated").hide();
        $(".gof_free").addClass("db-portal-filter-active");
        $(".gof_all, .gof_gated").removeClass("db-portal-filter-active");
      });
      $(".gof_gated").on("click", function () {
        $("#db-portal-fade").hide();
        $("#db-portal-fade").fadeIn(1000);
        $(".gof-free").hide();
        $(".gof-gated").show();
        $(".gof_gated").addClass("db-portal-filter-active");
        $(".gof_all, .gof_free").removeClass("db-portal-filter-active");
      });
      $(".gof_all").on("click", function () {
        $("#db-portal-fade").hide();
        $("#db-portal-fade").fadeIn(1000);
        $(".gof-free, .gof-gated").show();
        $(".gof_all").addClass("db-portal-filter-active");
        $(".gof_free, .gof_gated").removeClass("db-portal-filter-active");
      });

      // Event Handlers
      function registerEventHandlers() {
        self.on("input keyup change", "input, textarea", function () {
          if ($(this).attr("type") === "text") {
            $(this).css({
              border: "none",
            });
          } else if ($(this).attr("type") === "checkbox") {
            $(this).parent().css({
              border: "none",
            });
          }
          actions.swapLabels(this);
        });
      }

      // Initialization
      function init() {
        registerEventHandlers();
        actions.initialize();
        self.each(function () {
          actions.swapLabels($(this).find("input,textarea").first());
        });
      }

      init();
      return this;
    };
    $(function () {
      $(".float-label-control").floatLabels();
    });
  });

jQuery('.page-id-27772').find('#blockText').css("display","none");
jQuery('.page-id-27772').find('#fader').css("display","none");
jQuery('.page-id-27772').find('.blur').css("height","100%");
});
